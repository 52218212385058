import Contact from '../components/contact'
import Head from '../components/head'
import Hero from '../components/hero'
import HeroImage from '../assets/contactMe.jpg'
import Layout from '../components/layout'
import React from 'react'

const ContactPage = () => {
  return (
    <Layout>
      <Head title="Contact Us" />
      <Hero image={HeroImage} text="Say hello. Let's work together."/>
      <Contact/>
    </Layout>
  )
}

export default ContactPage