import '../styles/index.scss'

import Fade from 'react-reveal/Fade';
import Footer from '../components/footer'
import Header from '../components/header'
import React from 'react'
import layoutStyle from '../styles/layout.module.scss'

const DefaultLayout = (props) => {
    return (
        <div>
            <Header className={layoutStyle.header}/>
                <Fade>
                    <div className={layoutStyle.content}>
                        {props.children}
                    </div>
                </Fade>
            <Footer className={layoutStyle.footer}/>
        </div>
    )
}

export default DefaultLayout