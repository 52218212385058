import Particles from "react-particles-js"
import React from "react"
import contactStyle from "../styles/contact.module.scss"

const Contact = () => {
  return (
    <div className={contactStyle.container}>
      <Particles
        className={contactStyle.particleBG}
        params={{
          particles: {
            number: { value: 100 },
            size: { value: 1 },
            color: { value: "#000000" },
            line_linked: { color: "#000000" },
          },
        }}
      />
      <div className={contactStyle.contactCard}>
        <p className={contactStyle.introduction}>Find me on social media, use the form below, or hit me up via email at milandeepbassi@gmail.com.</p>
        <form name="contact" method="POST" data-netlify="true">
          <p>
            <label className={contactStyle.contactNameLabel}>
              Your Name: <input type="text" name="name" className={contactStyle.contactName}/>
            </label>
          </p>
          <p>
            <label className={contactStyle.contactEmailLabel}>
              Your Email: <input type="email" name="email" className={contactStyle.contactEmail} />
            </label>
          </p>
          <p>
            <label className={contactStyle.contactMessageLabel}>
              Message: <textarea name="message" className={contactStyle.contactMessage}></textarea>
            </label>
          </p>
          <p>
            <button type="submit" className={contactStyle.buttonSend}>Send</button>
          </p>
        </form>
      </div>
    </div>
  )
}

export default Contact
