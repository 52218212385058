import React from 'react'
import heroStyle from "../styles/hero.module.scss"

const Hero = (props) => {
    return (
        <div className={heroStyle.hero}>
            <div>
                <h1 className={heroStyle.section} style={{backgroundImage: "url(" + props.image + ")"}}><span className={heroStyle.message}> {props.text} </span></h1>
            </div>
        </div>
    )
}

export default Hero