import { Link, graphql, useStaticQuery } from 'gatsby'

import React from 'react'
import headerStyle from "../styles/header.module.scss"

const Header = () => {
    
    const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
    
    return (
        <header className={`${headerStyle.header}`}>
            <nav>
                <ul className={headerStyle.navList}>
                    <li>
                        <Link className={`${headerStyle.navItem}`} activeClassName={`${headerStyle.activeNavItem}`} to="/">YouTube</Link>
                    </li>
                    <li>
                        <Link className={`${headerStyle.navItem}`} activeClassName={`${headerStyle.activeNavItem}`} to="/">Designs</Link>
                    </li>
                    <li>
                        <Link className={`${headerStyle.navItem}`} activeClassName={`${headerStyle.activeNavItem}`} to="/">Projects</Link>
                    </li>
                </ul>
            </nav>
            <h1>
                <Link to="/" className={`${headerStyle.title}`}>
                    {data.site.siteMetadata.title}
                </Link>
            </h1>
            <nav>
                <ul className={headerStyle.navList}>
                    <li>
                        <Link className={`${headerStyle.navItem}`} activeClassName={`${headerStyle.activeNavItem}`} to="/blog">Blog</Link>
                    </li>
                    <li>
                        <Link className={`${headerStyle.navItem}`} activeClassName={`${headerStyle.activeNavItem}`} to="/">About Me</Link>
                    </li>
                    <li>
                        <Link className={`${headerStyle.navItem}`} activeClassName={`${headerStyle.activeNavItem}`} to="/contact">Contact Me</Link>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default Header